<script setup lang="ts">
import {
    Drawer,
    // initAccordions, 
    // initCarousels, 
    // initCollapses, 
    // initDials, 
    // initDismisses, 
    initDrawers,
    // initDropdowns,
    // initModals,
    // initPopovers,
    // initTabs,
    // initTooltips
} from 'flowbite'

// initialize components based on data attribute selectors
onMounted(() => {
    // initAccordions();
    // initCarousels();
    // initCollapses();
    // initDials();
    // initDismisses();
    initDrawers();
    // initDropdowns();
    // initModals();
    // initPopovers();
    // initTabs();
    // initTooltips();
})


const model = defineModel<boolean>()
function close() {
}

let isMenuHidden = ref(true)
const toggleSubMenu = () => {
    isMenuHidden.value = !isMenuHidden.value
}

</script>

<template>

    <!-- drawer component -->
    <div id="drawer-right-menu" ref="drawer-right-menu" class="fixed top-0 right-0 z-50 h-screen transition-transform duration-500 translate-x-full w-full md:w-[640px] "
        tabindex="-1" aria-labelledby="drawer-right-label">
        <div class="p-4 flex-1 bg-[#394830] text-white min-w-full h-full overflow-x-hidden">
            <div class="ml-24 whitespace-nowrap">
                <div class="flex justify-end">
                    <button class="cross-container change mr-6 mt-2" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">
                        <div class="bar1 bg-gray-300"></div>
                        <div class="bar2 bg-gray-300"></div>
                        <div class="bar3 bg-gray-300"></div>
                    </button>
                </div>
                <div class="flex flex-row items-center mr-24 ">
                    <div class="w-1/2">
                        <Logo />
                    </div>
                    <div class="w-1/2">
                        <Search />
                    </div>
                </div>
                <div class="mr-24">
                    <hr class="mb-10 mt-5" />
                </div>
                <div class="flex flex-col items-start justify-normal h-full ml-15 mr-24">
                    <ul class="text-3xl space-y-3">
                        <li>
                            <NuxtLink to="/about" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">About</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/how-we-work/" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">How we work</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/why-storm" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">Why STORM</NuxtLink>
                        </li>
                        <li class="space-y-3">
                            <NuxtLink to="#" @click="toggleSubMenu" class="">Products</NuxtLink>
                            <ul v-show="!isMenuHidden" class="ml-8 text-base space-y-3">
                                <li class="space-y-1">
                                    <NuxtLink to="/products/mvcls/" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">1. Modular Vehicle Combat and Logistics
                                        System (MVCLS)</NuxtLink>
                                    <ul v-show="!isMenuHidden" class="ml-8 pt-2 text-base space-y-3">
                                        <li>
                                            <NuxtLink to="/products/mvcls/mvcls-docklock" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">&gt; DockLock
                                            </NuxtLink>
                                        </li>
                                        <li>
                                            <NuxtLink to="/products/mvcls/mvcls-milcap-rough" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">&gt; MilCap
                                            </NuxtLink>
                                        </li>
                                        <li>
                                            <NuxtLink to="/products/mvcls/mvcls-tubkit" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">&gt; TubKit
                                            </NuxtLink>
                                        </li>
                                        <li>
                                            <NuxtLink to="/products/mvcls/mvcls-stackpack" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">&gt; StackPack
                                            </NuxtLink>
                                        </li>
                                        <li>
                                            <NuxtLink :to="{ path: '/products/mvcls', query: { filter: 'kits', yourCustomParam: Date.now() } }" data-drawer-hide="drawer-right-menu"
                                                aria-controls="drawer-right-menu">&gt; Kits</NuxtLink>
                                        </li>
                                        <li>
                                            <NuxtLink :to="{ path: '/products/mvcls', query: { filter: 'accessories', yourCustomParam: Date.now() } }" data-drawer-hide="drawer-right-menu"
                                                aria-controls="drawer-right-menu">&gt; Accessories
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </li>
                                <li class="space-y-1">
                                    <NuxtLink to="/products/weapon-integration" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">2. Weapons Integration /
                                        Brackets</NuxtLink>
                                    <ul v-show="!isMenuHidden" class="ml-8 pt-2 text-base space-y-3">
                                        <li>
                                            <NuxtLink to="/products/weapon-integration/mvcls-m72-law-bracket-h" data-drawer-hide="drawer-right-menu"
                                                aria-controls="drawer-right-menu">&gt; M72 LAW</NuxtLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NuxtLink to="/news" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">News</NuxtLink>
                        </li>
                        <li class="hidden">
                            <NuxtLink to="#" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">Partners and suppliers</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/team" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">Team</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/environment" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">Environment</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/contact" data-drawer-hide="drawer-right-menu" aria-controls="drawer-right-menu">Contact</NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="fixed bottom-10 right-10 flex flex-col space-y-2">
                <NuxtLink to="#" class="text-white">
                    <Icon name="uil:facebook-f" color="white" size="32" />
                </NuxtLink>
                <NuxtLink to="#" class="text-white">
                    <Icon name="uil:instagram" color="white" size="32" />
                </NuxtLink>
                <NuxtLink to="#" class="text-white">
                    <Icon name="uil:linkedin" color="white" size="32" />
                </NuxtLink>
                <NuxtLink to="#" class="text-white">
                    <Icon name="uil:at" color="white" size="32" />
                </NuxtLink>
            </div>
        </div>
    </div>

    <div class="hidden" :ui="{ width: 'max-w-2xl', height: '100%' }">
    </div>
</template>
