<template>
    <form class="max-w-md mx-auto">
        <label for="default-search" class="mb-2 text-sm font-medium sr-only ">Search</label>
        <div class="relative">
            <div class="absolute inset-y-0 end-4 flex items-center ps-3 pointer-events-none text-gray-500">
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
            </div>
            <input type="search" id="default-search" class="block w-full p-1 ps-10 text-sm rounded-full bg-white text-gray-900 placeholder:text-gray-500" placeholder="Search..."
                required />
        </div>
    </form>
</template>