<template>
    <div class="bg-black flex flex-col gap-4 md:gap-1 md:flex-row w-full h-full p-[16px] text-[7pt]">
        <div class="flex flex-row w-[33vw] items-center whitespace-nowrap ml-3">
            <NuxtLink to="#" class="align-middle">© STORM Defence 2024. All rights reserved</NuxtLink>
        </div>
        <div class="flex flex-row space-x-4 items-center whitespace-nowrap ml-3">
            <NuxtLink to="/terms-of-use" class="align-middle">Terms of Use</NuxtLink>
            <span>|</span>
            <NuxtLink to="/privacy-policy" class="align-middle">Privacy Policy</NuxtLink>
            <span>|</span>
            <NuxtLink to="/privacy-preferences" class="align-middle">Privacy Preferences</NuxtLink>
        </div>
    </div>
</template>