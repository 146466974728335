<template>
    <div class="flex flex-col text-xs sm:text-base md:flex-row gap-10 md:gap-20 justify-items-stretch w-full h-full bg-[#29321dff] text-gray-300 pl-16 pr-16 pb-8 align-middle">
        <div class="basis-1/3 h-full content-center mt-10 md:mt-[132px] lg:mt-[127px] xl:mt-[115px] justify-center">
            <NuxtImg src="/images/logo.svg" alt="Storm logo" class="logo w-64 md:w-48 lg:w-64 xl:w-96" />
        </div>
        <div class="flex flex-col basis-1/3 h-full content-center md:mt-[150px]">
            <hr class="mb-5" />
            <span>
                <NuxtLink to="/about">About</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/how-we-work">How we work</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/why-storm">Why STORM</NuxtLink>
            </span>
            <span v-if="false">
                <NuxtLink to="/products">Products</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/news">News</NuxtLink>
            </span>
            <span class="hidden">
                <NuxtLink to="/partners-and-suppliers">Partners and suppliers</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/team">Team</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/contact">Contact</NuxtLink>
            </span>
        </div>
        <div class="flex flex-col basis-1/3 h-full content-center md:mt-[150px]">
            <hr class="mb-5" />
            <span>
                <NuxtLink to="/press">Press</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/investor-information">Investor Information</NuxtLink>
            </span>
            <span>
                <NuxtLink to="/careers">Careers</NuxtLink>
            </span>
        </div>
    </div>
</template>