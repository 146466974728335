<template>
    <svg width="15.888052mm" height="13.861697mm" viewBox="0 0 15.888052 13.861697" version="1.1" id="svg1" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <defs id="defs1">
            <clipPath clipPathUnits="userSpaceOnUse" id="clipPath353">
                <path d="m 1294,954.017 h 45.038 V 993.31 H 1294 Z" transform="translate(-1324.4902,-979.89173)" id="path353" />
            </clipPath>
            <clipPath clipPathUnits="userSpaceOnUse" id="clipPath355">
                <path d="m 1294,954.017 h 45.038 V 993.31 H 1294 Z" transform="translate(-1316.5215,-986.62703)" id="path355" />
            </clipPath>
        </defs>
        <g id="layer1" transform="translate(-43.391707,-35.189584)">
            <g id="g350" transform="matrix(0.26458333,0,0,0.26458333,-1095.7279,4.6072693)">
                <g id="g777" />
                <path id="path352"
                    d="M 0,0 -1.36,-1.58 2.498,-6.096 -7.969,-16.564 -18.436,-6.096 -14.58,-1.58 -15.939,0 c -1.944,-0.599 -13.686,-4.232 -13.686,-4.232 l 2.334,-2.305 -0.008,-0.008 19.33,-19.33 19.329,19.33 -0.01,0.008 2.334,2.305 c 0,0 -12.3,3.788 -13.684,4.232"
                    style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(1.3333333,0,0,-1.3333333,4345.9869,133.47773)" clip-path="url(#clipPath353)" />
                <path id="path354"
                    d="m 0,0 c 6.601,0 12.88,-1.974 17.746,-5.555 l 4.77,4.771 C 22.398,-0.691 22.28,-0.596 22.161,-0.506 16.09,4.131 8.22,6.683 0,6.683 c -8.431,0 -16.399,-2.648 -22.521,-7.467 l 4.762,-4.765 C -13.075,-2.105 -6.84,0 0,0"
                    style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(1.3333333,0,0,-1.3333333,4335.362,124.49733)" clip-path="url(#clipPath355)" />
            </g>
        </g>
    </svg>
</template>